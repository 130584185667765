.page-inner {}

.breadcrumb {
	display: flex;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
	color: #646464;
	margin-top: 24px;


	a {
		text-decoration: none;
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		color: #646464; }

	svg {
		width: 10px;
		height: 10px; }

	li {

		&:last-child {

			a:after {
				display: none; } }


		a:after {
			content: '/';
			margin: 0 5px; } } }

section.catalog__inner {
	background: var(--white-bg-color);
	padding: 30px 0 10px;
	position: relative;
	overflow: hidden;

	.catalog__title {
		margin-left: 0;
		padding-right: 10px;
		padding-left: 10px; }

	.catalog__img {
		margin-bottom: 20px; }

	.row .col-12 {
		margin-bottom: 30px; }

	.triangle__color {
		bottom: auto;
		left: auto;
		top: -50px;
		right: -100px; }

	.catalog__item {
		display: flex;
		align-items: center;
		padding: 15px 20px;
		border-radius: 12px;
		background: #F8F8F8;
		margin-bottom: 30px;
		color: #2C2D2E;
		line-height: 28px;
		transition-duration: 0.25s;

		&:hover {
			box-shadow: 0px 8px 24px 8px #F0F0F0;
			background: #fff;

			.catalog__image {
				display: none; }

			.catalog__image-hover {
				display: block; } }

		.catalog__img {
			flex-basis: 75px;
			height: 75px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 35px;

			img {
				max-width: 100%; } }

		.catalog__image, .catalog__image-hover {
			width: 100%;
			max-height: 100%; }

		.catalog__image-hover {
			display: none; }

		.catalog__title {
			flex: 1 0; } }

	.catalog__item-inner {
		display: flex;
		align-items: center;
		padding: 15px;
		border-radius: 12px;
		background: #F8F8F8;
		margin-bottom: 0px;
		color: #2C2D2E;
		line-height: 28px;
		transition-duration: 0.25s;
		flex-direction: column;
		height: 100%;
		text-align: center;
		margin-bottom: 30px;

		.catalog__img {

			img {
				max-width: 100%; } }

		&:hover {
			box-shadow: 0px 8px 24px 8px #F0F0F0;
			background: #fff; } } }


.content__tabs {
	display: flex;
	margin-bottom: 25px;
	flex-wrap: wrap;

	a {
		display: block;
		color: var(--red);
		padding: 10px;
		text-decoration: none;
		border-bottom: 1px solid var(--red);
		margin-right: 15px;
		margin-bottom: 5px;

		&.active {
			color: #ffffff;
			background: var(--red); } } }

.content__tab {

	& > div {
		display: none;

		&.active {
			display: block; } }

	.content__table {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		margin-bottom: 10px;

		img {
			max-width: none; } }

	table {
		width: 100%;

		tr {
			height: auto !important; }

		td {
			padding: 5px 5px;
			border: 1px solid rgba(0,0,0,.1);
			height: auto !important;

			p:last-child {
				margin-bottom: 0; } }

		thead {
			background: var(--red);
			color: var(--white);

			td {
				border: none; } } }

	#files.active {
		display: flex;
		font-size: 18px;
		flex-direction: column;

		a {
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			i {
				margin-right: 15px; } } }

	.form__calc {
		display: flex;

		& + .form__calc {
			border-top: 1px solid #ccc;
			margin-top: 30px;
			padding-top: 30px; }

		&.img-width {

			.form__img {
				width: 300px; } }

		.form__img {
			width: 80px;
			margin-right: 30px;

			img {
				display: block;
				width: 100%; } }

		.form__calc-inner {
			flex: 1 0;
			display: flex;
			flex-direction: column;

			.form__calc-input {
				display: flex;
				flex-wrap: wrap;

				label {
					display: flex;
					flex-direction: column;
					min-width: 260px;
					margin-right: 30px;
					margin-bottom: 30px; }

				input {
					border: 1px solid #ccc;
					padding: 10px;
					font-size: 16px;
					width: 100%;
					margin-top: 5px;
					height: 42px; } }

			.btn {
				height: 42px;
				width: 260px;
				background: var(--red);
				color: var(--white); }

			.result {
				margin-top: 30px;

				li {
					padding-left: 0;
					display: flex;

					span {
						font-weight: 900;
						color: var(--red);
						margin-left: 5px; }

					&::before {
						display: none; } } } } } }

section.content {
	background: var(--white-bg-color);
	_line-height: 33px;

	img {
		max-width: 100%;
		height: auto; }

	.row {
		height: auto !important; }

	section.catalog {
		overflow: visible;
		padding: 0 0 30px 0; }

	.content__intro {
		_font-size: 28px;
		_line-height: 42px;
		margin-bottom: 30px; }

	p {
		margin-bottom: 10px; }

	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 10px 0;
		line-height: 120%;

		li {
			margin-bottom: 0px;
			position: relative;
			_display: flex;
			padding-left: 25px;

			& + li {
				margin-top: 10px; }

			&::before {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
				min-width: 10px;
				_margin-right: 15px;
				background: #21AA61;
				border-radius: 5px;
				top: 4px;
				left: 0;
				position: absolute; } } }

	.right__col {

		.catalog {

			.row > div {
				margin-bottom: 30px; }

			.catalog__item-inner {
				display: flex;
				align-items: center;
				padding: 15px;
				border-radius: 12px;
				_background: #F8F8F8;
				margin-bottom: 0px;
				color: #2C2D2E;
				transition-duration: 0.25s;
				flex-direction: column;
				height: 100%;
				text-align: center;
				box-shadow: 0px 8px 5px 2px #F0F0F0;
				text-decoration: none;
				font-weight: 400;

				.catalog__img {

					img {
						max-width: 50%; } }

				.catalog__title {}

				&:hover {
					box-shadow: 0px 8px 24px 8px #F0F0F0;
					background: #fff; } } } }

	.content__files {}

	.content__file {
		display: flex;
		font-size: 14px;
		line-height: 20px;
		padding: 40px;
		background: #F8F8F8; }

	.content__file-icon {
		flex-basis: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16px; }

	.content__file-desc {
		flex: 1 0; } }

.calc__list {
    margin-top: 35px; }

.calc__list-title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px; }

.calc__item {
    margin-bottom: 30px;
    padding: 25px;
    border-radius: 15px;
    background: rgba(0,0,0,.05);
    height: calc(100% - 30px); }

.calc__list.content__tab  .form__calc .form__img {
    width: 250px !important;
    min-width: 250px !important; }


.calc__list.content__tab  .form__calc-inner .form__calc-input label {
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
    min-width: 0; }

.calc__list.content__tab .form__calc .form__calc-inner .btn {
    width: 100%;
    max-width: 260px; }

@media (max-width: 1200px) {
    .calc__list.content__tab  .form__calc .form__img {
        width: 150px !important;
        min-width: 150px !important; } }


@media (max-width: 992px) {
    .calc__list.content__tab .form__calc {
        flex-direction: column;
        align-items: center; } }

.gallery_wrap {

	.gallery__item {
		position: relative;
		display: block;
		border-radius: 10px;
		overflow: hidden;
		transition-duration: 0.25s;
		margin-bottom: 30px;

		&:hover {
			box-shadow: 0 0 30px rgba(0,0,0,.35);

			.gallery__title {
				padding-bottom: 20px; } }

		.gallery__img {

			img {
				display: block;
				width: 100%; } }

		.gallery__title {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 10px;
			padding-top: 20px;
			color: #ffffff;
			text-align: center;
			background: linear-gradient(0deg, rgba(255,0,0,1) 0%, rgba(255,255,255,0) 100%);
			transition-duration: 0.25s; } } }


.gallery_inner {

	.gallery__item-inner {
		display: block;
		margin-bottom: 30px;

		&:hover {

			img {
				box-shadow: 0 0 30px rgba(0,0,0,.35); } }

		img {
			display: block;
			border-radius: 10px;
			transition-duration: 0.25s;
			width: 100%; } } }

.acc {
	border: 1px solid #ddd;
	margin-bottom: 40px;

	&.open {

		.acc__more {

			&::after {
				display: none; }

			&::before {
				display: block; } } }

	.acc__title {
		padding: 10px 15px;
		background: #ddd;
		font-size: 20px;
		cursor: pointer; }

	.acc__intro {
		padding: 10px 15px; }

	.acc__content {
		display: none;
		padding: 10px 15px; }

	.acc__more {
		width: 200px;
		margin: 0 auto;
		background: #ddd;
		padding: 10px 15px;
		text-align: center;
		color: #000;
		margin-bottom: -20px;
		position: relative;
		cursor: pointer;
		z-index: 5;
		transition-duration: 0.25s;
		position: sticky;
		bottom: 0;

		&:hover {
			box-shadow: 0 0 30px rgba(0,0,0,.1); }

		&::after {
			content: 'Показать весь текст'; }

		&::before {
			content: 'Скрыть текст';
			display: none; } } }


.gallery__inner {
	display: flex;
	position: relative;
	margin: 0 -15px;

	.slider-nav__button {
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50%;
		left: 25px;
		transform: translateY(-50%);
		border-radius: 50%;
		border: 1px solid var(--color-font-red);
		background: var(--color-font-red);
		cursor: pointer;
		z-index: 2;

		svg {

			path {
				fill: #fff; } }

		&.slider-nav__next {
			left: auto;
			right: 25px;
			padding-left: 10px; }

		&.slider-nav__prev {
			padding-right: 10px; } }

	.slick-list {
		padding-top: 0px;
		padding-bottom: 30px; }

	.gallery__inner-inner {
		padding: 0 15px;

		a {
			display: block;
			transition-duration: 0.25s;

			&:hover {
				box-shadow: 0 0 20px rgba(0,0,0,.25); }

			img {
				display: block;
				width: 100%;
				border: 1px solid #ccc; } } } }

body.page-id-111 section.catalog__inner .row .col-12 {
  flex: 0 0 calc(100% / 1);
  max-width: calc(100% / 1); }

@media (min-width: 768px) {
  body.page-id-111 section.catalog__inner .row .col-12 {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2); } }


@media (min-width: 992px) {
  body.page-id-111 section.catalog__inner .row .col-12 {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7); } }

body.page-id-11 section.catalog__inner .row .col-12 {
    flex: 0 0 calc(100% / 1);
    max-width: calc(100% / 1); }

@media (min-width: 768px) {
    body.page-id-11 section.catalog__inner .row .col-12 {
        flex: 0 0 calc(100% / 2);
        max-width: calc(100% / 2); } }

@media (min-width: 992px) {
    body.page-id-11 section.catalog__inner .row .col-12 {
        flex: 0 0 calc(100% / 6);
        max-width: calc(100% / 6); } }

@media (min-width: 1200px) {
    body.page-id-11 section.catalog .row .col-12 {
        flex: 0 0 calc(100% / 7);
        max-width: calc(100% / 7); } }

@media (min-width: 1300px) {
    body.page-id-11 section.catalog__inner .row .col-12 {
        flex: 0 0 calc(100% / 8);
        max-width: calc(100% / 8); } }

