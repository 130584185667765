footer {
	flex: 0 0 auto;
	background: #000000;
	color: $white;
	padding: 75px 0;

	.container {
		max-width: 840px; } }

.footer__copy {
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
	color: var(--darkgray); }

.footer-nav__list {
	display: flex;
	flex-direction: column; }

.footer-nav__item {

	& + .footer-nav__item {
		margin-top: 20px; } }

.footer-nav__link {
	font-family: $add-font-family;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: var(--white);
	text-decoration: none;
	position: relative;

	&:hover {

		&::after {
			background:  var(--red); } }

	&::after {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		height: 1px;
		background: var(--white);
		bottom: 0;
		transition-duration: $trds; } }

.footer__top {
	display: flex;

	@include tablet {
		flex-direction: column; } }

.footer__nav {
	width: 60%;

	@include tablet {
		width: 100%; } }

.footer__contacts {
	width: 40%;

	@include tablet {
		width: 100%;
		margin-top: 20px; } }

.contacts__list--footer {
	margin-top: 40px; }
