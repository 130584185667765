.page-inner.page-delivery {

	.content {
		padding-bottom: 0; } }

.delivery {
	padding-bottom: 70px; }

.delivery__tabs {
	display: flex;
	justify-content: center;
	margin-bottom: 70px;

	@include minMobile {
		flex-direction: column; } }

a.delivery__tab {
	font-size: 24px;
	color: #646464;
	font-family: $add-font-family;
	border: 2px solid #CECECE;
	line-height: 14px;
	padding: 16px 20px;
	display: block;
	text-align: center;

	&.active {
		color: var(--red);
		border-color: var(--red); }

	& + .delivery__tab {
		margin-left: 13px;

		@include minMobile {
			margin-left: 0;
			margin-top: 13px; } } }

.delivery__tab {

	& > div {
		display: none;

		&.active {
			display: block; } } }

.delivery-types__item + .delivery-types__item {
	margin-top: 50px; }

.delivery-types__title {
	font-weight: 700;
	font-size: 22px;
	font-family: $add-font-family;
	padding: 12px 19px;
	background: #F2F2F2;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	color: #000;

	span {
		background: var(--red);
		width: 38px;
		height: 38px;
		border-radius: 50%;
		font-family: 30px;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 22px; } }

.delivery-types__text {

	ul {

		li {

			&::before {
				width: 3px !important;
				height: 3px !important;
				background: #000 !important;
				min-width: 3px !important;
				top: 7px !important;
				left: 10px !important;
				border-radius: 50% !important; } } } }

.delivery__comp {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@include mobile {
		flex-direction: column; } }

.delivery-comp__text {
	flex: 1 0;
	margin-right: 100px;

	@include mobile {
		margin-right: 0; } }

.delivery-comp__fire {
	display: flex;
	font-weight: 700;

	svg, img {
		width: 21px;
		height: 27px;
		margin-right: 19px; } }

.delivery-comp__imgs {
	width: 340px;
	display: flex;
	flex-wrap: wrap;

	@include mobile {
		width: 100%; } }

.delivery-comp__img {
	display: flex;
	align-items: flex-end;
	padding: 15px;
	justify-content: center;
	width: 50%;

	&:first-child {
		align-items: center; } }

.delivery-comp__list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		font-weight: 700;
		background: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_1061_233)"><path d="M12.61 0C5.65 0 0 5.65 0 12.61C0 19.57 5.65 25.22 12.61 25.22C19.57 25.22 25.22 19.57 25.22 12.61C25.22 5.65 19.58 0 12.61 0ZM17.34 7.24L19.56 9.46L11.04 17.98L5.67 12.61L7.89 10.39L11.04 13.54L17.35 7.23L17.34 7.24Z" fill="%23E3000F"/></g><defs><clipPath id="clip0_1061_233"><rect width="25.22" height="25.22" fill="white"/></clipPath></defs></svg>') no-repeat;
		padding-left: 52px !important;
		min-height: 25px;
		padding-top: 3px;

		span {
			font-weight: 400; }

		&::before {
			display: none !important; }

		& + li {
			margin-top: 30px !important; } } }

.delivery-comp___warn {
	padding-left: 52px;
	font-size: 12px;
	margin-top: 20px;

	span {
		color: var(--red); } }

.type__info {
	font-weight: 700;
	margin-top: 25px;

	span {
		color: var(--red); } }

.buy {
	background: #F2F2F2;
	padding-top: 80px;
	padding-bottom: 80px;

	@include mobile {
		padding-top: 40px;
		padding-bottom: 40px; } }

.buy__wrap {
	display: flex;
	justify-content: space-between;

	@include mobile {
		flex-direction: column; } }

.buy__col {
	width: calc(50% - 25px);

	@include mobile {
		width: 100%;

		& + .buy__col {
			margin-top: 50px; } } }

.buy__list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		font-weight: 700;
		background: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_1061_233)"><path d="M12.61 0C5.65 0 0 5.65 0 12.61C0 19.57 5.65 25.22 12.61 25.22C19.57 25.22 25.22 19.57 25.22 12.61C25.22 5.65 19.58 0 12.61 0ZM17.34 7.24L19.56 9.46L11.04 17.98L5.67 12.61L7.89 10.39L11.04 13.54L17.35 7.23L17.34 7.24Z" fill="%23E3000F"/></g><defs><clipPath id="clip0_1061_233"><rect width="25.22" height="25.22" fill="white"/></clipPath></defs></svg>') no-repeat;
		padding-left: 52px;
		min-height: 25px;
		padding-top: 3px;

		& + li {
			margin-top: 25px; } } }

.buy__subtitle {
	font-family: $add-font-family;
	font-size: 24px;
	color: #000;
	margin-bottom: 35px; }

.buy__info {
	display: flex;
	margin-top: 60px;

	@include mobile {
		margin-top: 25px; }

	svg, img {
		width: 38px;
		height: 38px;
		margin-right: 15px;
		min-width: 38px; } }

.delivery-docs__info {}

.delivery-docs__title {
	display: flex;
	margin-top: 40px;
	padding: 12px 20px;
	border-top: 1px solid #E6E6E6;
	border-bottom: 1px solid #E6E6E6;
	justify-content: space-between;
	font-family: $add-font-family;
	font-size: 22px;
	align-items: center;
	width: 100%;
	cursor: pointer;

	&.open {

		.delivery-docs__arrow {

			svg, img {
				transform: rotate(90deg); } } }

	svg, img {
		width: 38px;
		height: 38px;
		margin-right: 20px;
		transition-duration: $trds; } }

.delivery-docs__arrow {
	margin-left: auto;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 20px;

	svg, img {
		width: 9px;
		height: 16px;
		display: block;
		margin-right: 0; } }

.delivery-docs__info-acc {
	padding-top: 40px;
	display: none; }

.area__item {
	display: flex;

	& + .area__item {
		margin-top: 23px; } }

.area-item__icon {
	width: 78px;
	height: 78px;
	min-width: 78px;
	margin-right: 38px; }

.area-item__desc {
	padding-top: 15px; }

.area-item__title {
	font-weight: 700;
	margin-bottom: 10px; }

.area-item__text {
	font-size: 12px; }
