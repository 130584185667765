.page-index {

	.content {
		padding: 0; } }

.slider {
	background: url(../images/content/header.jpg);
	background-size: cover;
	position: relative;
	height: 525px;
	display: flex;
	align-items: center;

	@include mobile {
		background: url(../images/content/header__mobile.jpg) right 0 no-repeat #010101;
		height: 430px; } }

.slider__desc {
	margin-left: 100px;
	font-weight: 700;
	font-size: 55px;
	line-height: 60px;
	color: var(--white);
	font-family: $add-font-family;
	max-width: 640px;

	@include tablet {
		font-size: 36px;
		line-height: 36px;
		margin-left: 15px;
		padding-right: 55px; } }

.catalog {
	padding-top: 100px;

	@include mobile {
		padding-top: 50px;
		padding-bottom: 0; } }

.catalog__list {
	margin: 0 -7px;
	display: flex;
	flex-wrap: wrap; }

.catalog__item {
	padding: 0 7px;
	width: 33.33%;
	margin-bottom: 14px;

	@include width1100 {
		width: 50%; } }

.catalog__link {
	background: #F2F2F2;
	border-radius: 3px;
	transition-duration: $trds;
	display: flex;
	align-items: center;
	text-decoration: none;

	@include mobile {
		flex-direction: column;
		padding-bottom: 15px;
		height: 100%;
		text-align: center;
		padding-right: 15px;
		padding-left: 15px; }

	&:hover {
		background: #E6E6E6;
		color: #000; } }

.catalog__title {
	margin-left: 30px;
	padding-right: 20px;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	font-family: $add-font-family;
	text-decoration: none; }

.gallery__dekstop {
	padding-left: 100px;
	padding-right: 100px;

	@include tablet {
		display: none; } }

.gallery-dekstop__slider.slick-slider.slick-dotted {
	margin-bottom: 36px; }

.gallery-dekstop__item {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-gap: 13px; }

.gallery-dekstop__link {
	position: relative;

	&:hover {

		.gallery-dekstop__zoom {
			opacity: 1;
			visibility: visible; } }

	svg {
		width: 25px;
		height: 25px; } }

.gallery-dekstop__zoom {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	opacity: 0;
	visibility: hidden;
	transition-duration: $trds; }

.gallery-dekstop__info--big {
	grid-column: 1 / 3;
	grid-row: 1 / 3; }

.gallery__mobile {
	display: none;

	@include tablet {
		display: block; } }

.gallery-mobile__info {
	width: 236px;
	margin-right: 14px; }

.gallery__more {
	display: flex;
	justify-content: center;
	padding-top: 50px; }

.gallery-more__link {
	width: 260px;
	height: 60px;
	border: 1px solid #CECECE;
	border-radius: 3px;
	color: #646464;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;

	&:hover {
		background: #F2F2F2;
		color: #646464;
		border: 1px solid #E6E6E6; } }

.gallery__popup {
	padding: 20px 188px;
	background: #fff;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%) scale(0);
	transform: translate(-50%,-50%);
	z-index: 101;
	width: 1110px;
	max-width: 90%;
	max-height: 80%;
	opacity: 0;
	visibility: hidden;
	transition-duration: $trds;

	.slick-prev {
		left: -125px; }

	.slick-next {
		right: -125px; }

	&.open {
		opacity: 1;
		visibility: visible;
		transform: translate(-50%,-50%) scale(1); }

	.slick-list {
		overflow-y: auto;

		@include scrollbars(8px,#000,#fff); } }

.gallery-popup__item-inner {
	display: flex;
	height: 100%;
	overflow-y: auto;
	align-items: center; }

.gallery-popup__img {
	width: 50%;
	padding-right: 46px; }

.gallery-popup__desc {
	width: 50%;
	padding-right: 15px; }

.gallery-popup__title {
	font-family: $add-font-family;
	font-weight: 700;
	font-size: 20px;
	line-height: 22px;
	margin-bottom: 20px; }

.gallery-popup__text {
	margin-bottom: 30px;
	font-size: 12px;
	line-height: 16px;
	color: #646464;

	ul {
		li {
			display: flex;

			&::before {
				content: '';
				display: block;
				width: 21px;
				height: 21px;
				min-width: 21px;
				background: url(../images/general/gal.svg) no-repeat;
				margin-right: 25px; } }

		li + li {
			margin-top: 15px; } } }

.gallery-popup__link {
	width: 170px;
	height: 40px;
	border: 1px solid #CECECE;
	border-radius: 3px;
	color: #646464;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;

	&:hover {
		background: #F2F2F2;
		color: #646464;
		border: 1px solid #E6E6E6; } }

.gallery-popup__close {
	width: 23px;
	height: 33px;
	position: absolute;
	top: 39px;
	right: 56px;

	svg {
		width: 23px;
		height: 33px; } }

.review__block {
	display: flex;
	position: relative;

	@include tablet {
		flex-direction: column; } }

.review__item {
	padding-right: 15px;
	min-width: 1px;
	width: 297px;

	@include tablet {
		width: 297px;
		height: 100%; } }

.review__slider {
	padding-right: calc(25% + 15px);
	min-width: 1px;

	.slick-list {
		border-right: 1px solid #E6E6E6;
		border-left: 1px solid #E6E6E6; }

	@include tablet {
		padding-right: 0; }

	.slick-prev {
		left: -28px; }

	.slick-next {
		right: -28px; } }

.review-item__inner {
	border: 1px solid #E6E6E6;
	border-radius: 3px;
	padding: 40px 40px 27px 40px; }

.review__item--img {

	.review-item__inner {
		padding: 20px 25px; } }

.review-item__top {
	display: flex;
	margin-bottom: 14px; }

.review-item__symbol {
	width: 54px;
	height: 54px;
	border-radius: 50%;
	background: var(--red);
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--white);
	margin-right: 10px;
	font-weight: 700;
	font-size: 40px;
	line-height: 22px;
	font-family: $add-font-family; }

.review-item__title {
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	font-family: $add-font-family;
	color: var(--black); }

.review-item__date {
	color: #646464;
	font-size: 10px;
	line-height: 16px;
	margin-top: 5px; }

.review-item__text {
	margin-bottom: 14px; }

.review-item__original {
	display: flex;
	align-items: center; }

.review-item__icon {
	width: 30px !important;
	margin-right: 10px; }

.review-item__link {
	font-size: 10px;
	line-height: 16px; }

.review__portal {
	position: absolute;
	top: 0;
	right: 0;
	width: 25%;
	border: 1px solid #E6E6E6;
	border-radius: 3px;
	padding: 40px 40px 27px 40px;
	height: calc(100%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@include tablet {
		position: relative;
		width: 100%;
		border: none;
		padding: 0;
		margin-top: 24px; } }

.review-portal__title {
	font-family: $add-font-family;
	font-weight: 700;
	font-size: 26px;
	line-height: 26px;
	text-align: center;
	color: #000000;
	margin-bottom: 25px;

	@include tablet {
		display: none; } }

.review-portal__list {
	display: flex;
	flex-direction: column;
	width: 100%;

	@include tablet {
		flex-direction: row;
		justify-content: space-between; } }

.review-portal__item {
	width: 100%;

	@include tablet {
		width: calc(50% - 7px); }

	& + .review-portal__item {
		margin-top: 18px;

		@include tablet {
			margin-top: 0; } } }

.review-portal__link {
	display: flex;
	border: 1px solid #E6E6E6;
	border-radius: 3px;
	align-items: center;
	width: 100%;
	padding: 12px;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	color: #000;
	text-decoration: none; }

.review-portal__icon {
	width: 30px;
	margin-right: 17px; }


.plus {
	background: #F2F2F2;
	padding-bottom: 0;
	padding-top: 100px;

	@include mobile {
		padding-top: 50px; }

	.container {
		max-width: 940px; } }


.plus__list {
	display: flex;
	flex-wrap: wrap; }

.plus__item {
	width: 50%;
	display: flex;
	align-items: center;
	margin-bottom: 50px;
	padding-right: 25px;

	@include mobile {
		width: 100%;
		padding-right: 0;
		margin-bottom: 20px; } }

.plus__title {
	font-weight: 700;
	font-size: 22px;
	line-height: 22px;
	font-family: $add-font-family;
	color: var(--black);
	margin-bottom: 10px; }

.plus__intro {
	font-size: 14px;
	line-height: 16px;
	color: #646464; }

.plus__img {
	margin-right: 35px;

	@include mobile {
		width: 77px;
		margin-right: 20px; } }

.contacts__list {}

.contacts__item {

	& + .contacts__item {
		margin-top: 30px; } }

.contacts-item__wrap {

	@include width1200 {
		padding-left: 25px; } }

.contacts__sub {
	color: #646464;
	font-family: $add-font-family;
	font-weight: 700;
	font-size: 30px;
	line-height: 36px; }

.contacts__title {
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	font-family: $add-font-family; }

.contacts__desc {
	font-size: 10px;
	line-height: 16px;
	color: #646464; }

.contacts__mail {
	font-size: 10px;
	line-height: 16px;
	color: var(--white); }

.contacts__phones {
	display: flex;
	margin-top: 20px; }

.contacts-phones__item {

	& + .contacts-phones__item {
		margin-left: 40px; } }

.contacts-phones__link {
	font-weight: 700;
	font-size: 18px;
	line-height: 14px;
	color: var(--red);
	font-family: $add-font-family;
	white-space: nowrap;
	text-decoration: none; }

.contacts-map {
	background: #F2F2F2; }

.contacts__list--map {
	display: flex;
	width: 100%;
	gap: 15px;

	@include tablet {
		flex-direction: column; }

	.contacts__item {
		width: 50%;

		@include tablet {
			width: 100%; }

		& + .contacts__item {
			margin-top: 0;

			@include tablet {
				width: 100%;
				margin-top: 30px; } } }

	.contacts__title {
		font-size: 40px;
		line-height: 48px;
		margin-bottom: 5px;
		position: relative;

		svg {
			position: absolute;
			width: 22px;
			height: 17px;
			left: -28px;
			top: 17px; } }

	.contacts__desc {
		font-size: 14px;
		margin-bottom: 10px; }

	.contacts__mail {
		color: #000; }

	.contacts__phones {
		margin-bottom: 28px; } }


.section-order__desc {
	text-align: center;
	color: #646464;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 50px; }

.section-order__form {
	display: flex;
	width: 100%;
	max-width: 550px;
	margin: 0 auto;
	margin-bottom: 30px; }

.section-order__label {
	display: flex;
	width: 100%;

	@include mobile {
		flex-direction: column;
		align-items: center; } }

.section-order__input {
	flex: 1 1 auto;
	height: 60px;

	@include mobile {
		margin-bottom: 23px; } }

.section-order__btn {
	width: 175px;
	margin-left: 15px; }

.section-order__politic {
	text-align: center;
	font-size: 10px;
	line-height: 16px;
	color: #646464; }
