header {
	_height: 100px;

	@include tablet {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 10; }

	@include mobile {
		height: 60px; } }

.header__top {
	height: 100px;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 10;
	background: #fff;
	box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);

	& > .container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100px;

		@include mobile {
			height: 60px; } }

	@include mobile {
		height: 60px; } }

.header__catalog {

	@include mobile {
		display: none; } }

.header-catalog__link {
	background: var(--red);
	display: flex;
	align-items: center;
	height: 43px;
	padding: 0 16px;
	border-radius: 6px;
	font-family: $add-font-family;
	color: var(--white);
	text-decoration: none;
	white-space: nowrap;

	svg {
		width: 16px;
		height: 16px;
		margin-right: 19px;

		&.icon-close {
			display: none; } }

	&:hover {
		color: var(--white);
		background: #D00310; }

	&.open {
		background: #646464;

		.icon-nav {
			display: none; }

		.icon-close {
			display: inline; } } }

.header__logo {
	height: 100%; }

.header-logo__link {
	display: block;

	svg {
		width: 128px;
		height: 70px; } }

.header-logo__img {
	width: 128px;

	@include mobile {
		width: 88px; } }

.nav {
	height: 100%;

	@include tablet {
		display: none; }

	.container {
		height: 100%; } }

.nav__list {
	display: flex;
	height: 100%; }

.nav__link {
	white-space: nowrap;
	font-weight: 700;
	font-size: 18px;
	line-height: 14px;
	font-family: $add-font-family;
	color: var(--black);
	text-decoration: none;
	display: flex;
	align-items: center;
	height: 100%; }

.nav__item {
	position: relative;
	height: 100%;

	& + .nav__item {
		margin-left: 18px; }

	&.parent {

		svg {
			width: 8px;
			height: 6px;
			margin-left: 6px;
			margin-top: 2px; }

		&:hover {

			.nav__dropdown {
				opacity: 1;
				visibility: visible;
				margin-top: 0; } } }


	&.parent--dropdown {

		&.open {

			& > .nav__link {
				color: var(--red);

				svg {
					fill: var(--red); } }

			.nav__dropdown {
				opacity: 1;
				visibility: visible; } } } }

.nav__dropdown {
	padding: 32px 56px;
	background: #fff;
	box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 50%;
	top: 100%;
	transform: translateX(-50%);
	transition-duration: $trds;
	margin-top: 25px; }

.header__contacts {
	display: flex;
	flex-direction: column;
	align-items: flex-end; }

.header-contacts__phones {
	display: flex;
	margin-bottom: 5px; }

.header-contacts__mail {

	@include mobile {
		display: none; } }

.header-phones__link {
	font-weight: 700;
	font-size: 18px;
	line-height: 14px;
	font-family: $add-font-family;
	color: var(--red);
	text-decoration: none; }

.header-phones__item {

	& + .header-phones__item {
		margin-left: 25px;

		@include mobile {
			display: none; } } }

.header-mail__link {
	font-size: 12px;
	line-height: 14px;
	color: #646464;

	&:hover {
		color: #000;
		text-decoration: underline; } }

a.open__nav {
	background: var(--red);
	display: flex;
	align-items: center;
	width: 33px;
	height: 29px;
	border-radius: 3px;
	font-family: $add-font-family;
	color: var(--white);
	text-decoration: none;
	white-space: nowrap;
	transition: .5s ease-in-out;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	display: none;

	@include mobile {
		display: flex; }

	svg {
		width: 16px;
		height: 16px;
		margin-right: 1px;

		&.icon-close {
			display: none; } }

	@include mobile {
		display: flex; }

	&.open {
		background: #646464;

		.icon-nav {
			display: none; }

		.icon-close {
			display: inline; } }

	span {
		display: block;
		position: absolute;
		height: 4px;
		width: 100%;
		background: #484848;
		border-radius: 3px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0px; }

		&:nth-child(2), &:nth-child(3) {
			top: 7px; }

		&:nth-child(4) {
			top: 14px; } } }

.contacts__list--header {

	.contacts__sub {
		font-size: 14px;
		line-height: 16px; }

	.contacts__title {
		font-size: 24px;
		line-height: 29px;
		color: #000; }

	.contacts__mail {
		color: #646464; }

	.contacts__item {

		& + .contacts__item {

			padding-top: 30px;
			border-top: 1px solid #E6E6E6; } } }

.dropdown__catalog {
	background: #fff;
	top: 100px;
	position: fixed;
	left: 0;
	right: 0;
	box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
	padding-top: 25px;
	padding-bottom: 10px;
	transition-duration: $trds;
	margin-top: 25px;
	visibility: hidden;
	opacity: 0;
	z-index: 9;

	&.open {
		margin-top: 0;
		visibility: visible;
		opacity: 1; }

	.catalog__item {
		width: 25%;

		&:hover {

			.catalog__img-w {
				background: #E6E6E6; } } }

	.catalog__img-w {
		width: 100px;
		height: 100px;
		background: #F2F2F2;
		min-width: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition-duration: $trds; }

	.catalog__img {
		max-width: 100px;
		max-height: 100px;
		_border-radius: 50%; }

	.catalog__link {
		background: transparent;

		&:hover {
			background: transparent;
			color: var(--red); } }


	.catalog__title {
		font-size: 16px;
		line-height: 16px; } }
