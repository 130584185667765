 :root {
	--red: #E60000;
	--white: #FFFFFF;
	--black: #000000;
	--darkgray: #646464; }

@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
	height: 100%;
	background: #fff; }

body {
	font: 400 14px/16px $base-font-family;
	color: #3E3F42;
	height: 100%;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	min-width: 300px;
	background: transparent;
	padding-top: 100px;

	@include mobile {
		padding-top: 60px; } }

*,
*::before,
*::after {
	box-sizing: border-box; }

ul,
ol {
	list-style: none;
	padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0; }

a:not([class]) {
	text-decoration-skip-ink: auto; }

img {
	_max-width: 100%;
	_display: block; }

input,
button,
textarea,
select {
	font: inherit;
	width: 100%; }

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
	vertical-align: middle; }

textarea {
	resize: none; }

section {
	position: relative; }

input,
select,
button {
	outline: none; }

.d-f {
	display: flex; }

.a-c {
	align-items: center; }

.j-c {
	justify-content: center; }

a {
	color: var(--black);
	transition-duration: $trds;

	&:hover {
		text-decoration: none;
		color: var(--red); } }
.btn {
	display: flex;
	height: 60px;
	align-items: center;
	justify-content: center;
	border: 1px solid $black;
	text-decoration: none;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	border: 1px solid #E3000F;
	transition-duration: $trds;
	color: var(--white);
	background: #E3000F;
	border-radius: 3px;
	width: 176px;
	min-width: 176px;

	&:hover {
		background: #D00310; } }

.input {
	font-family: $base-font-family;
	border: 1px solid #CECECE;
	border-radius: 3px;
	padding-left: 25px;
	padding-right: 25px; }

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%; }

.content {
	flex: 1 0 auto;
	padding: 32px 0 24px;
	background: transparent;
	line-height: 20px;

	a {
		color: var(--red);
		text-decoration: none; }

	table {
		border-spacing: 0;
		border: 0; }

	h1 {
		font-weight: 700;
		font-size: 55px;
		margin-bottom: 40px;

		@include tablet {
			font-size: 30px;
			line-height: 30px;
			margin-bottom: 40px; } }

	h2 {
		font-weight: 700;
		font-size: 32px;
		line-height: 44px;
		margin-bottom: 15px;
		font-family: $add-font-family;

		@include tablet {
			font-size: 20px;
			line-height: 28px;
			margin-bottom: 15px; } }

	h3 {
		text-transform: none;
		font-weight: 700;
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 20px;
		font-family: $add-font-family;

		@include tablet {
			font-size: 18px;
			margin-bottom: 20px; } }

	.h1 {
		font-weight: 500;
		font-size: 32px;
		line-height: 120%;
		color: #121216;
		margin-bottom: 36px;
		font-family: $add-font-family;

		@include tablet {
			font-size: 24px;
			margin-bottom: 20px; } }

	.h2 {
		font-weight: 500;
		font-size: 32px;
		line-height: 120%;
		color: #121216;
		margin-bottom: 36px;
		font-family: $add-font-family; }

	p {
		margin-bottom: 20px; } }

.container {
	margin: 0 auto;
	max-width: $content-size;
	width: 100%;
	padding: 0 15px; }

.section {
	padding: 50px 0; }

.section__title {
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
	font-family: $add-font-family;
	text-align: center;
	margin-bottom: 45px;
	color: var(--black); }

.popup {
	display: none;

	&.open {
		display: block; } }

#popup__form--header {
	padding: 30px 32px;
	position: absolute;
	right: 0;
	left: auto;
	transform: translate(0);
	top: 86px;
	width: 336px;
	background: #FFFFFF;
	border: 1px solid #E0E0E0;
	border-radius: 24px;

	.form-label__title {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		color: #484848;
		margin-bottom: 8px; }

	.form-label__input {
		height: 48px;
		border: 1px solid #484848;
		border-radius: 24px; } }

.hidden {
	display: none; }

.popup__title {
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	color: #000000;
	margin-bottom: 35px;
	font-family: $add-font-family;

	@include mobile {
		font-size: 22px;
		margin-bottom: 20px;
		line-height: 22px; } }

.popup {
	position: fixed;
	width: 100%;
	max-width: 737px;
	padding: 60px 60px 60px 60px;
	background: #fff;
	z-index: 101;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);

	@include mobile {
		max-width: 282px;
		padding: 53px 23px 23px 23px; }




	.popup__close {
		position: absolute;
		right: 14px;
		top: 14px;

		svg {
			stroke: #999999;
			width: 24px;
			height: 24px; } }

	form.form {

		.form-label__input {
			border: 1px solid #999999;

			&.error {
				background: #FFF5F5;
				border: 1px solid #E5372E;

				& + .form-label__req {
					color: #E5372E; } } }


		.button {
			margin-left: auto;
			margin-right: auto;
			height: 60px;
			background: var(--red);
			font-weight: 700;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 380px;
			border-radius: 3px;
			border: none;
			cursor: pointer;

			@include mobile {
				margin-top: 20px; } } }

	.form__politic {
		margin-top: 8px;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		color: #646464; }

	.form__wrap {
		display: flex;
		justify-content: center;
		gap: 10px;
		flex-wrap: wrap;

		@include mobile {
			flex-direction: column; }

		.form-group {
			width: calc(33.33% - 7px);

			@include mobile {
				width: 100%; }

			&.w2 {
				width: calc(50% - 7px); }

			&.w1 {
				width: calc(100%); }

			.form-control {
				background: #FFFFFF;
				border: 1px solid #CECECE;
				border-radius: 3px;
				height: 58px;
				padding-left: 15px;
				padding-right: 15px;

				&.error {
					border-color: var(--red); } }

			textarea.form-control {
				width: 100%;
				height: 100px;
				padding-top: 10px;
				padding-bottom: 10px; } } } }

.form__success {
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition-duration: 0.5s;

	&.open {
		display: flex;
		opacity: 1;
		visibility: visible; } }


.form-success__title {
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	color: #000000;
	margin-bottom: 35px;
	font-family: $add-font-family;
	margin: 25px 0;

	@include mobile {
		font-size: 22px;
		margin-bottom: 20px;
		line-height: 22px; } }

/*.popup__close
	position: absolute
	top: 15px
	right: 15px

	&:hover
		svg
			fill: $orange

	svg
		fill: #8E8E8E
		width: 12px
		height: 12px*/

.popup__politic {
	padding: 40px 90px;
	background: #fff;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%) scale(0);
	transform: translate(-50%,-50%);
	z-index: 101;
	width: 1110px;
	max-width: 90%;
	max-height: 90%;
	opacity: 0;
	visibility: hidden;
	transition-duration: $trds;

	@include tablet {
		padding: 40px; }

	&.open {
		opacity: 1;
		visibility: visible;
		transform: translate(-50%,-50%) scale(1); } }

.politic-popup__close {
	width: 23px;
	height: 33px;
	position: absolute;
	top: 39px;
	right: 56px;

	@include tablet {
		right: 14px;
		top: 7px; }

	svg {
		width: 23px;
		height: 33px; } }

.politic__inner {
	overflow-y: auto;
	padding-right: 25px;

	@include scrollbars(8px,#ccc,rgba(0,0,0,.025));

	p {
		margin-bottom: 20px; }

	h3 {
		font-weight: 700;
		font-size: 30px;
		line-height: 38px;
		color: #000000;
		margin-bottom: 20px;
		font-family: $add-font-family;

		@include tablet {
			font-size: 24px;
			line-height: 30px; } } }


.politic__title {
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	color: #000000;
	margin-bottom: 25px;
	font-family: $add-font-family;

	@include tablet {
		font-size: 30px;
		list-style: 38px; } }

.popup__wrap {
	padding: 23px;
	background: #fff;
	width: 100%;
	max-width: 650px;

	.form__label {
		margin-bottom: 12px; }

	.form__input {
		background: #FFFFFF;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		height: 46px;
		font-family: $base-font-family;
		font-size: 15px;
		padding-left: 17px;
		padding-right: 17px;
		width: 100%; }

	.form__button {
		width: 100%;
		height: 46px;
		color: #fff;
		margin-bottom: 0; }

	.form__title {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		color: #1C1D1F;
		margin-bottom: 25px;
		text-align: center; }

	.form__subtitle {
		font-size: 13px;
		line-height: 16px;
		color: #8E8E8E;
		margin-bottom: 12px; } }

.overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.3);
	display: none;

	@include tablet {
		top: 60px; } }

.nav__mobile {
	position: fixed;
	z-index: 101;
	left: 0;
	top: 60px;
	bottom: 0;
	width: 100%;
	max-width: 360px;
	background: $white;
	transform: translateX(-100%);
	transition-duration: $trds;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-top: 1px solid #C7C7C7;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	text-align: left;
	padding: 15px 0;

	&.open {
		transform: translateX(0%); }

	.lang__title {
		margin-left: 0;
		margin-top: 20px; } }


.nav-mobile__close {
	width: 24px;
	height: 24px;

	svg {
		width: 24px;
		height: 24px;
		stroke: $white;
		position: absolute;
		top: 20px;
		right: 20px; } }

.nav-mobile__list {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 23px;
	width: 100%; }

.nav-mobile__item {
	padding: 0 36px;

	&.parent {

		// &:hover

		// 	.nav-mobile__list-childs
		// 		display: block

		svg {
			width: 12px;
			height: 6px;
			// margin-left: 4px
 } }			// margin-bottom: 6px

	& + .nav-mobile__item {
		margin-top: 8px; } }

.nav-mobile__list-childs {
	display: none;
	background: #F2F2F2;
	width: calc(100% + 72px);
	margin: 0 -36px;
	padding: 0 36px; }

.nav-mobile__link-child {
	text-decoration: none;
	padding: 10px 0;
	border-bottom: 1px solid #E6E6E6;
	display: flex;
	font-family: $add-font-family;
	font-weight: 700;
	font-size: 14px;
	line-height: 14px;
	align-items: center;

	&:hover {
		text-decoration: underline; } }

.nav-mobile__item-img {
	width: 70px;
	margin-right: 25px; }

.nav-mobile__link-open {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: -7px 0 -11px; }

.nav-mobile__link {
	font-weight: 700;
	font-size: 18px;
	line-height: 15px;
	font-family: $add-font-family;
	color: $black;
	text-decoration: none;
	color: #000000;
	border-bottom: 1px solid #E6E6E6;
	display: block;
	padding: 20px 0;
	display: flex;

	&:hover {
		color: var(--red); } }

.nav-mobile__one-link {
	font-weight: 600;
	font-size: 18px;
	line-height: 133%;
	color: $black;
	text-decoration: none;
	margin-bottom: 8px;
	display: block;
	color: $orange;

	&:hover {
		color: $black; } }

.nav-mobile__logo {
	margin-bottom: 8px;

	.nav-mobile__link {

		svg {
			width: 155px;
			height: 40px;
			fill: $white; } } }

.nav-mobile__button {
	margin-bottom: 20px;

	&:hover {
		color: $white; } }

.nav-mobile__contacts {
	display: flex;
	flex-direction: column; }

.nav-mobile__phone {
	color: $white;
	text-decoration: none;
	margin-bottom: 20px;
	font-weight: 600; }

.nav-mobile__email {
	font-weight: 600;
	font-size: 18px;
	line-height: 28px; }

.mobile-search__open {
	margin-bottom: 20px;

	svg {
		width: 20px;
		height: 20px; } }

.nav__search {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	background: $white;
	display: flex;
	align-items: center;
	padding-left: 38px;
	padding-right: 38px;
	transition-duration: $trds;
	opacity: 0;
	visibility: hidden;

	&.open {
		opacity: 1;
		visibility: visible; } }

.nav-search__form {
	position: relative;
	width: 100%; }

.nav-search__button {
	position: absolute;
	z-index: 2;
	left: 14px;
	top: 14px;
	width: 20px;
	height: 20px;
	background: transparent;
	border: none;
	padding: 0;
	cursor: pointer;

	svg {
		width: 20px;
		height: 20px; } }

.nav-search__input {
	height: 48px;
	width: 100%;
	border: 1px solid #484848;
	border-radius: 24px;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: $black;
	padding-left: 48px;
	padding-right: 24px;

	@include placeholder {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		color: $black; } }

.nav-search__close {
	width: 24px;
	height: 24px;
	position: absolute;
	top: 20px;
	right: 20px;

	svg {
		width: 24px;
		height: 24px;
		stroke: $white; } }


.breacrumbs {
	font-size: 18px;
	line-height: 133%;
	margin-bottom: 31px;
	color: #A5ACB5;

	@include mobile {
		font-size: 14px;
		line-height: 170%; }

	.breacrumbs__list {
		display: flex;
		flex-wrap: wrap; }

	.breacrumbs__item {

		&::after {
			content: '/';
			margin-left: 4px;
			margin-right: 4px; }

		&:last-child {

			&::after {
				display: none; } } }

	.breacrumbs__link {
		color: #A5ACB5;
		text-decoration: none; } }

.slick-slider {

	.slick-next:before, .slick-prev:before {
		display: none; }

	.slick-slide {

		img {
			width: 100%; } }

	.slick-arrow {
		background: #D9D9D9;
		width: 56px;
		height: 56px;
		border-radius: 100%; }

	.slick-prev, .slick-next {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		z-index: 5;
		cursor: pointer;
		transition-duration: $trds; }

	.slick-prev {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		left: -100px; }

	.slick-next {
		right: -100px;
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E"); }

	.slick-prev:focus {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-prev:hover {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-next:focus {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-next:hover {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-dots {
		display: flex;
		justify-content: center;
		bottom: -36px;
		flex-wrap: wrap;

		li {
			padding: 0;
			margin: 0;
			width: 61px;
			height: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 4px 10px;

			&.slick-active {

				button {
					background: var(--red);
					width: 61px;
					height: 3px; } }

			button {
				background: #DBDBDB;
				width: 61px;
				height: 3px;
				padding: 0;
				transition-duration: $trds; }

			button:before {
				display: none; } }

		li:before {
			display: none; } } }

.float__icon {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: calc(50% - 30px);
    left: 100%;
    background: var(--red);
    border-radius: 2px;
    z-index: 5;
    box-shadow: 0 0 20px rgba(255,0,0,.5);
    transform: translateX(-60px);
    transition-duration: 0.25s; }

.float__recall {
	display: flex;
	color: #fff;
	font-size: 28px;
	cursor: pointer;
	white-space: nowrap;
	//animation: 2.5s linear 0s infinite call
	transition-duration: 0.25s;
	background: transparent;
	text-decoration: none; }

.float__recall:hover {
	background: rgba(255,255,255,.15);
	color: var(--white); }

.float__recall + .float__recall {
    border-top: 1px solid rgba(255,255,255,.25); }

.float__icon:hover {
	transform: translateX(-100%);

	// @include tablet
 }	// 	transform: translateX(-60px)

.recall__img {
	width: 61px;
	align-items: center;
	display: flex;
	height: 60px;
	min-width: 61px;
	justify-content: center;
	border-right: 1px solid rgba(255,255,255,.25);

	svg {
		width: 70%;
		max-height: 60px; } }

.recall__text {
	height: 60px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 18px;
	font-family: $add-font-family; }

.input-file {
	position: relative;
	display: inline-block;
	margin: 40px 0;

	@include mobile {
		display: none; } }

.input-file span span {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: dashed;
	font-family: $add-font-family;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #000000;
	border-bottom: 1px dashed #000; }

.input-file svg {
	width: 15px;
	height: 15px;
	margin-right: 15px; }

.input-file input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0; }

.input-file input[type=file]:focus + span {
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); }

.input-file:hover span span {
	border-color: #fff; }

.input-file:active span {}


.input-file input[type=file]:disabled + span {}


//Модули
@import "modules/modules";
